import { For, Show } from "solid-js";
import type { EventColumn } from "../_model";
import EventCard from "./event-card";

export default function Column(props: EventColumn & { monthName: string }) {
  return (
    <div class="flex flex-col  bg-gray-100 px-1 py-2 rounded-2xl md:(min-w-25.1vw max-w-25.1vw h-fit) <md:(w-full h-full)">
      <div class="flex flex-row justify-center items-center justify-between px-2 text-18px py-2 border-b-2 border-solid border-gray-300">
        <span class="text#txtgray font-bold ">{props.year}</span>
        <p class="text#orange font-bold">
          {props.day} {props.monthName}
        </p>
        <span class="text#orange font-bold  text#txtgray">{props.dayOfWeek}</span>
      </div>
      <div>
        <Show when={props?.events}>
          <For each={props?.events}>
            {(event) => {
              return <EventCard {...event} />;
            }}
          </For>
        </Show>
      </div>
    </div>
  );
}
